/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";

import { useOnClickOutside } from "App";
import api from "services/api";
import { PERMISSIONAB } from "services/constants";

import SidebarItem, { sidebarData, ticketsSharedPaths } from "./sidebarItem";

import "./styles.css";
import New_api from "services/new-api";

const packageJSON = require("../../../package.json");
const { location } = window;

let container;

export function activeSide() {
  const sidebar = document.getElementById("sidebar");
  sidebar.classList.add("sidebar-call");
}

export default function Sidebar(props) {
  const ref = useRef(null);

  const [auth, setAuth] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [expandedSubItemIndexes, setExpandedSubItemIndexes] = useState([]);
  const [isEstablishment, setIsEstablishment] = useState(false);
  const [isAB, setIsAB] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user.type || [];

  const isAdmin =
    userRole === "GOD"
      ? "version-container-admin"
      : "version-container-producer";

  const event = {
    id: new URLSearchParams(location.search).get("event_id"),
  };

  const permittedSidebarItems = filterSidebarByPermission(
    sidebarData,
    userRole,
    location.pathname
  );

  function filterSidebarByPermission(data, userPermissions, currentPagePath) {
    const filteredData = [];

    const matchPath = (path) =>
      (typeof path === "string" && currentPagePath === path) ||
      (path instanceof RegExp && currentPagePath.match(path));

    for (const item of data) {
      if (item.label === "Estabelecimentos" && !isEstablishment) {
        continue;
      } else {
        if (item.subItems) {
          item.subItems = filterSidebarByPermission(
            item.subItems,
            userPermissions,
            currentPagePath
          );
        }

        const hasPermission =
          !item.permittedRoles ||
          item.permittedRoles.some((role) => userPermissions.includes(role));

        if (item.label === "Voltar") {
          item.redirectPath = "/events";
        }
        if (item.matchPath) {
          if (Array.isArray(item.matchPath)) {
            if (item.matchPath.some(matchPath) && hasPermission) {
              filteredData.push(item);
            }
          } else {
            if (matchPath(item.matchPath) && hasPermission) {
              filteredData.push(item);
            }
          }
        } else if (hasPermission) {
          filteredData.push(item);
        }
      }
    }
    return filteredData;
  }

  function handleSubItemToggle(index, isExpanded) {
    if (isExpanded) {
      setExpandedSubItemIndexes([...expandedSubItemIndexes, index]);
    } else {
      setExpandedSubItemIndexes(
        expandedSubItemIndexes.filter((subIndex) => subIndex !== index)
      );
    }
  }

  function DropDown(position) {
    if (openDropdownIndex === position) {
      setOpenDropdownIndex(null);
    } else {
      setOpenDropdownIndex(position);
      setExpandedSubItemIndexes([]);
    }
  }

  function toggleSide() {
    if (openDropdownIndex !== null) {
      setOpenDropdownIndex(null);
    }

    const icon = document.querySelector(".toggle-icon");
    if (props.sidebarClassName === "sidebar-toggle") {
      props.setSidebarClassName("");
      document.documentElement.style.setProperty("--sidebar-width", "13.75rem");
      icon.classList.replace("menu-shape", "x-shape");
    } else {
      props.setSidebarClassName("sidebar-toggle");
      document.documentElement.style.setProperty("--sidebar-width", "3.75rem");
      icon.classList.replace("x-shape", "menu-shape");
    }
  }

  function AlterPainelIngresso() {
    api
      .get(`/event/typeEvent/${event.id}`)
      .then((res) => {
        if (res.data?.type === null) {
          (async () => {
            await Swal.fire({
              text: "Percebemos que seu evento não está configurado para o módulo de bar(A&b). Para habilitar o painel de bar e acessar a gestão do seu evento, escolha abaixo o modelo de operação que você deseja configurar.",
              icon: "warning",
              input: "select",
              inputOptions: {
                ticket: "Ticket (Ficha)",
                "cashless-pos": "Cashless Pós Pago",
                "cashless-pre": "Cashless Pré Pago",
              },
              inputPlaceholder: "Selecione o modelo de operação",
              showCancelButton: true,
              cancelButtonText: "Deixar para depois",
              confirmButtonText: "Completar cadastro",
              inputValidator: (value) => {
                return new Promise((resolve) => {
                  switch (value) {
                    case "cashless-pos":
                      resolve();
                      activePainelBar(value, event.id);
                      break;
                    case "cashless-pre":
                      resolve();
                      activePainelBar(value, event.id);
                      break;
                    case "ticket":
                      resolve();
                      activePainelBar(value, event.id);
                      break;
                    default:
                      resolve("Você deve selecionar um modelo de operação");
                      break;
                  }
                });
              },
            });
          })();
        } else {
          window.location.href = `/bar/dashboard?event_id=${event.id}`;
        }
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  }

  function activePainelBar(typeEvent, event_id) {
    let type = typeEvent;
    const params = { type, event_id: event_id };

    api
      .post("update/updatetypepainelaeb", params)
      .then((res) => {
        if (res) {
          window.location = `/bar/dashboard?event_id=${event_id}`;
        }
      })
      .catch(() => {
        container.error("Erro ao salvar");
      });
  }

  useEffect(() => {
    filterSidebarByPermission(sidebarData, userRole, location.pathname);
    setSidebarVisible(true);
    // eslint-disable-next-line
  }, [userRole, location]);

  useEffect(() => {
    setAuth(true);

    const sidebar = document.getElementById("sidebar");
    const icon = document.querySelector(".toggle-icon");

    if (
      (sidebar.className === "c3 " ||
        sidebar.className === "c3 sidebar-call") &&
      icon.className === "toggle-icon menu-shape"
    ) {
      icon.classList.replace("menu-shape", "x-shape");
      document.documentElement.style.setProperty("--sidebar-width", "13.75rem");
    }
  }, [auth, props]);

  useEffect(() => {
    const hasEstablishment = user?.events?.length > 0 || user.is_admin;
    const { setSidebarClassName } = props;
    const isMobile = window.matchMedia("(max-width: 768px)");

    if (isMobile.matches) {
      setSidebarClassName("sidebar-toggle");
    }
    setIsEstablishment(hasEstablishment);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (event.id) {
      typeEventCheck(event.id);
    } else {
      setIsAB(false);
    }
    // eslint-disable-next-line
  }, [event.id]);

  const typeEventCheck = async () => {
    const {
      data: { type },
    } = await New_api.get(`/events/${event.id}`, {
      params: { fields: ["type"] },
    });

    setIsAB(!!type);
  };

  useOnClickOutside(ref, () => {
    const isMobile = window.matchMedia("(max-width: 768px)");

    if (isMobile.matches && props.sidebarClassName === "sidebar-call") {
      props.setSidebarClassName("sidebar-toggle");
    }
  });

  return (
    <nav
      id="sidebar"
      className={`c3 ${
        sidebarVisible ? props.sidebarClassName : "sidebar-toggle"
      }`}
      ref={ref}
    >
      <div className="sidebar-header">
        <img id="sidebar-logo" src="/logo_web.png" alt="company logomark" />
        <button
          type="button"
          className="toggle-icon x-shape"
          onClick={toggleSide}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
      </div>
      {(PERMISSIONAB && ticketsSharedPaths.includes(location.pathname)) ||
        (isAB && ticketsSharedPaths.includes(location.pathname) && (
          <button className="button-aeb" onClick={AlterPainelIngresso}>
            <img alt="toggle-icon" src={"/icons/buttonZig.svg"} />
            <p>Painel A&B (bar)</p>
          </button>
        ))}
      <ul id="sidebar-list">
        {permittedSidebarItems.map((data, index) => {
          return (
            <Fragment key={index}>
              <SidebarItem
                data={data}
                parentProps={props}
                index={index}
                onSubItemToggle={handleSubItemToggle}
                isExpanded={expandedSubItemIndexes.includes(index)}
                DropDown={() => DropDown(index)}
                isOpen={openDropdownIndex === index}
                eventId={event.id}
              />
            </Fragment>
          );
        })}
      </ul>
      {process.env.REACT_APP_ENABLE_ZIG_OP_PANEL === "1" && (
        <div className="redirect-button-zig">
          <button className="button-zig">
            <img src={"/icons/buttonZig.svg"} alt="zig-dashboard-icon" />
            Painel operações
          </button>
        </div>
      )}
      <div className={isAdmin}>{packageJSON.version}</div>
    </nav>
  );
}
