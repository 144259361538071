import React, { useEffect, useState, useRef, Fragment } from "react";
import Api from "../../../../services/api";
import { useOnClickOutside } from "App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import QRCode from "qrcode.react";
import { BiLoaderAlt } from "react-icons/bi";
import { breakPoints } from "services/breakPoints";
import styled from "styled-components";
import { COLORS, FONTS_SIZE } from "services/constants";
import "./styles.css";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const ModalProducer = (props) => {
  const { t } = useTranslation();
  const [infoProducer, setInfoProducer] = useState({
    userID: null,
    nameEvent: "",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    qrcode: "",
  });
  const [refreshModal, setRefreshModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchProducerDetails = async (idEvent) => {
    setLoading(true);
    try {
      const res = await Api.get(`/details/Producer/event/${idEvent}`);
      setInfoProducer({
        userID: res.data[0]?.id,
        nameEvent: res.data[0]?.name,
        firstName: res.data[0]?.first_name,
        lastName: res.data[0]?.last_name,
        email: res.data[0]?.email,
        telephone: res.data[0]?.telephone,
        qrcode: res.data[0]?.qrcode,
      });
      setLoading(false);
    } catch (error) {
      Swal.fire({
        title: "Ops!",
        icon: "error",
        text: "Ocorreu um erro interno, por favor tente novamente",
        confirmButtonText: "Ok",
      });
    }
  };

  useEffect(() => {
    if (props.idEvent || refreshModal) {
      fetchProducerDetails(props.idEvent);
    }
  }, [props.idEvent, refreshModal]);

  // Click outside
  let ref = useRef();
  useOnClickOutside(ref, () => {
    if (props.status === "modal-show") {
      close();
    }
  });

  function close() {
    props.callback("modal-hidden");
  }

  const gerarQRCODE = async () => {
    setLoading(true);
    try {
      if (!infoProducer.userID) {
        await fetchProducerDetails(props.idEvent);
      }
      await Api.get(`/gera/qrcode/user/${infoProducer.userID}`);
      setLoading(false);
      setRefreshModal(true);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Ops!",
        icon: "error",
        text: "Ocorreu um erro interno, por favor tente novamente",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <Fragment>
      <div
        ref={ref}
        className={"shadow side-modal " + props.status}
        role="dialog"
      >
        <div className="col-12">
          <div className="card-header container-register-user">
            <h4 className="card-header-title">
              {t("events:eventList.eventProducerInfoModal.title")}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => close(e)}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12" id="producer-infos">
                <p>
                  <span>{t("global:Event")}:</span>
                  <span>{infoProducer.nameEvent}</span>
                </p>
                <p>
                  <span>
                    {t(
                      "events:eventList.eventProducerInfoModal.labels.producerName"
                    )}
                    :
                  </span>
                  <span>
                    {infoProducer.firstName + " " + infoProducer.lastName}
                  </span>
                </p>
                <p>
                  <span>{t("global:Email")}:</span>
                  <span>
                    <a href={`mailto: ${infoProducer.email}`}>
                      {infoProducer.email}
                    </a>
                  </span>
                </p>
                <p>
                  <span>
                    {t(
                      "events:eventList.eventProducerInfoModal.labels.contact"
                    )}
                    :
                  </span>
                  <span>
                    {infoProducer.telephone}
                    <a
                      href={`https://api.whatsapp.com/send?phone=55${infoProducer.telephone}&text=TktWave`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faWhatsapp}
                        className="ml-4 mr-1"
                      />
                      {t(
                        "events:eventList.eventProducerInfoModal.callOnWhattsapp"
                      )}
                    </a>
                  </span>
                </p>
              </div>
            </div>
            <div
              style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  paddingBottom: "10px",
                  fontWeight: "bold",
                  color: "#283e59",
                  fontSize: "1.1em",
                }}
              >
                {t("events:eventList.eventProducerInfoModal.producerQrCode")}
              </span>
              {infoProducer.qrcode && (
                <QRCode size={140} value={infoProducer.qrcode} />
              )}
              {!infoProducer.qrcode && !loading && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>
                    {t(
                      "events:eventList.eventProducerInfoModal.noQRCodeMessage"
                    )}
                  </span>
                  <div style={{ paddingTop: "10px" }}>
                    <button
                      className="btn btn-primary c3"
                      onClick={async () => await gerarQRCODE()}
                    >
                      {t(
                        "events:eventList.eventProducerInfoModal.QRCodeButtonText"
                      )}
                    </button>
                  </div>
                </div>
              )}
              {loading && (
                <LoadingContainer>
                  <LoadingIcon color={COLORS.darkBlue} size={80} />
                  <LoadingText>
                    {t(
                      "events:eventList.eventProducerInfoModal.QRCodeLoadingText"
                    )}
                  </LoadingText>
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const LoadingContainer = styled.div`
  flex-direction: column;
  min-width: 32px;
  height: calc(100% - 240px);
  align-items: center;
  display: flex;
  justify-content: center;
  @media ${breakPoints.tablet} {
    height: calc(100% - 180px);
  }
`;
const LoadingIcon = styled(BiLoaderAlt)`
  animation: rotate 1.5s linear infinite;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
const LoadingText = styled.span`
  color: ${COLORS.gray};
  font-size: ${FONTS_SIZE.fontSize0};
  height: 42;
  width: 80%;
  @media ${breakPoints.mobileL} {
    font-size: ${FONTS_SIZE.fontSize1};
    width: auto;
  }
`;

export default ModalProducer;
